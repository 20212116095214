import { useGate } from "@nestoca/gate-react";
import { useTenant } from "@nestoca/multi-tenant";
import { Flex, useBreakpointValue } from "@nestoca/ui";
import { useRouter } from "next/router";

import { DashboardHighlightsCard } from "@components/dashboard-highlights-card";
import { RateCard } from "@components/rate-card/rate-card";
import { RateCardMobile } from "@components/rate-card/rate-card.mobile";

const ratesLinks: Record<string, Record<string, string>> = {
  // fallback for all tenants
  "*": {
    en: "https://sites.google.com/nesto.ca/faqsbrokerchannel/english#h.malpkko7p9e7",
    fr: "https://sites.google.com/nesto.ca/faqsbrokerchannel/fran%C3%A7ais#h.jyuilkeya795",
  },
  cmls: {
    en: "https://www.cmls.ca/brokers/rate-sheets",
    fr: "https://www.cmls.ca/brokers/rate-sheets",
  },
};

export const DashboardHighlightsGroup = () => {
  const { locale = "en" } = useRouter();
  const tenant = useTenant();

  const isMobile = useBreakpointValue({ default: true, md: false });
  const gate = useGate();
  const role = gate.getRole();
  const isExternalBroker = role === "externalbroker";

  let ratesLinkByLocale = ratesLinks["*"][locale];
  if (tenant?.slug && ratesLinks[tenant.slug]?.[locale]) {
    ratesLinkByLocale = ratesLinks[tenant.slug][locale];
  }

  return (
    <Flex gap={3}>
      {(!isMobile || !isExternalBroker) && <DashboardHighlightsCard />}
      {isExternalBroker &&
        (isMobile ? (
          <RateCardMobile ratesLink={ratesLinkByLocale} />
        ) : (
          <RateCard ratesLink={ratesLinkByLocale} />
        ))}
    </Flex>
  );
};
